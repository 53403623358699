const theme = {
  space: [0, 4, 8, 16, 24, 30, 32, 40, 48, 64, 100, 120],

  fonts: {
    body: '"IBM Plex Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
  },

  fontSizes: [12, 14, 16, 17, 20, 24, 36, 43, 46],

  fontWeights: {
    body: 400,
    heading: 500,
    light: 300,
    medium: 500,
    bold: 600,
  },

  lineHeights: {
    body: 1.61,
    heading: 1.3,
    headingLarge: 1.2,
  },

  letterSpacings: {
    body: "normal",
    heading: "-0.01em",
    caps: "0.13em",
    links: "0.01em",
  },

  colors: {
    primary: {
      accent: "hsl(20deg, 100%, 95%)",
      light1x: "hsl(20deg, 100%, 60%)",
      base: "hsl(20deg, 100%, 50%)",
    },
    secondary: {
      base: "hsl(41deg, 100%, 50%)",
      dark: "hsl(41deg, 100%, 46%)",
    },
    tertiary: {
      base: "hsl(199deg, 98%, 48%)",
      dark: "hsl(207deg, 91%, 53%)",
    },
    background: {
      base: "hsl(0, 0%, 100%)",
      dark: "hsl(180deg, 13%, 97%)",
      dark2x: "hsl(180deg, 13%, 92%)",
    },
    text: {
      light3x: "hsla(0, 0%, 66%, 1)",
      light2x: "hsla(0, 0%, 46%, 1)",
      light: "hsla(0, 0%, 31%, 1)",
      base: "hsla(0, 0%, 22%, 1)",
      dark: "hsla(0, 0%, 13%, 1)",
      darker: "hsl(0, 0%, 0%)",
    },
    muted: "hsl(0, 0%, 89%)",
    border: {
      base: "hsl(0, 0%, 89%)",
      dark: "hsl(0deg, 0%, 81%)",
    },
  },

  text: {
    default: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 3, // 18px
    },
    xs: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 2, // 16px
    },
    sm: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 3, // 17px
    },
    md: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [4, null, null, null, 5], // 24px
      color: "text.dark",
    },
    lg: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: ["28px", null, null, null, 6], // 36px
      color: "text.dark",
      letterSpacing: "heading",
    },
    xl: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "headingLarge",
      fontSize: [6, null, null, null, 7], // 43px
      color: "text.dark",
      letterSpacing: "heading",
    },
    xxl: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "headingLarge",
      fontSize: [6, null, null, null, 8], // 46px
      color: "text.dark",
      letterSpacing: "heading",
    },
    uppercase: {
      fontSize: 1,
      fontWeight: "heading",
      lineHeight: "heading",
      color: "text.light2x",
      textTransform: "uppercase",
      letterSpacing: "caps",
    },
  },

  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      color: "text.base",
    },
    h1: {
      color: "text.dark",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 8,
    },
    h2: {
      color: "text.dark",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 7,
    },
    h3: {
      color: "text.dark",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
    },
    h4: {
      color: "text.dark",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    h5: {
      color: "text.dark",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
    },
    h6: {
      color: "text.dark",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
    },
    p: {
      color: "text.base",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary.base",
      cursor: "pointer",
      "&:hover": {
        color: "secondary",
      },
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    hr: {
      borderTop: "1px solid",
      borderBottom: "none",
      borderColor: "border.dark",
      mt: 9,
      mb: 0,
    },
  },

  layout: {
    full: {
      maxWidth: "100%",
    },
    container: {
      maxWidth: "1348px",
      px: [4, null, null, 5],
    },
    narrow: {
      maxWidth: "790px",
      px: [4, null, null, 5],
    },
    narrow2x: {
      maxWidth: "647px",
      px: 5,
    },
  },

  buttons: {
    primary: {
      fontSize: 4,
      fontWeight: "medium",
      backgroundColor: "primary.base",
      color: "background.base",
      p: "4px 36px",
      border: "3px solid",
      borderColor: "primary.base",
      borderRadius: 0,
      cursor: "pointer",
      textTransform: "uppercase",
      "&:hover": {
        backgroundColor: "background",
        color: "primar.base",
      },
    },
    secondary: {
      fontSize: 4,
      fontWeight: "medium",
      backgroundColor: "background",
      color: "primary.base",
      p: "4px 36px",
      border: "3px solid",
      borderColor: "primary.base",
      borderRadius: 0,
      cursor: "pointer",
      textTransform: "uppercase",
      "&:hover": {
        backgroundColor: "primary.base",
        color: "background",
      },
    },
  },

  forms: {
    select: {
      border: "none",
      padding: 0,
    },
    label: {
      fontSize: 5,
      cursor: "pointer",
    },
    checkbox: {
      width: "23px",
      height: "23px",
      cursor: "pointer",
    },
  },

  links: {
    default: {
      color: "primary.base",
    },
  },

  breakpoints: ["640px", "768px", "1024px", "1280px"],

  radii: [0, 2, 4, 6, 8, 11],

  shadows: {
    cards: "3px 1px 18px 3px rgb(212 221 221)",
    navigation: "0px 1px 14px -3px rgb(159 159 159 / 26%)",
    navDropdownMenu: "0px 17px 17px -15px rgb(159 159 159 / 26%)",
  },
}

export default theme
